export const enum TrackedComponents {
  AddContentHyperlink = "AddContentHyperlink",
  AddHostConfirmModal = "AddHostConfirmModal",
  AnalyticsBoard = "AnalyticsBoard",
  AnalyticsHeader = "AnalyticsHeader",
  AnimationsPicker = "AnimationsPicker",
  AppSettings = "AppSettings",
  AuthlessUpsellToast = "AuthlessUpsellToast",
  AuthlessUserNameModal = "AuthlessUserNameModal",
  AvatarHeadGenerationModal = "AvatarHeadGenerationModal",
  Backpack = "Backpack",
  BackpackItemDetails = "BackpackItemDetailsModal",
  BackpackItemOverflowMenu = "BackpackItemOverflowMenu",
  BrandExperiences = "BrandExperiences",
  BrandsPage = "BrandsPage",
  BuyItemModal = "BuyItemModal",
  CameraModeButtons = "CameraModeButtons",
  CategoryPage = "CategoryPage",
  CoinPackages = "CoinPackages",
  CoinsCheckoutModal = "CoinsCheckoutModal",
  CoinsPage = "CoinsPage",
  CollectibleEnvironmentsList = "CollectibleEnvironmentsList",
  ContentMenuCreatorToolkitTab = "ContentMenuCreatorToolkitTab",
  ContentMenuFurnitureTab = "ContentMenuFurnitureTab",
  ContentMenuIntegrationsTab = "ContentMenuIntegrationsTab",
  ContentMenuModal = "ContentMenuModal",
  ContentMenuNftTab = "ContentMenuNftTab",
  ContentMenuRecentTab = "ContentMenuRecentTab",
  ContentMenuSketchfabTab = "ContentMenuSketchfabTab",
  ContentMenuStuffTab = "ContentMenuStuffTab",
  ContentMenuTemplatesTab = "ContentMenuTemplatesTab",
  ContentMenuTrashTab = "ContentMenuTrashTab",
  ContentMenuUploadTab = "ContentMenuUploadTab",
  ContentPage = "ContentPage",
  CreateCustomEnvModal = "CreateCustomEnvModal",
  CreateHyperlinkPortal = "CreateHyperlinkPortal",
  CreatePortalModal = "CreatePortalModal",
  CreateYourHome = "CreateYourHome",
  CreatorToolkitEnvironmentsList = "CreatorToolkitEnvironmentsList",
  CreatorToolkitPage = "CreatorToolkitPage",
  CubemapPreview = "CubemapPreview",
  CubemapPreviewMesh = "CubemapPreviewMesh",
  CustomizeYourIdentity = "CustomizeYourIdentity",
  DisabledBackpackItemBanner = "DisabledBackpackItemBanner",
  DockMenu = "DockMenu",
  DownloadPage = "DownloadPage",
  DragAndDropFilesOverlay = "DragAndDropFilesOverlay",
  EditCategoryModal = "EditCategoryModal",
  EditCustomEnvironmentBanner = "EditCustomEnvironmentBanner",
  EditSpaceInfo = "EditSpaceInfo",
  EditUserProfile = "EditUserProfile",
  EmailPasswordLogin = "EmailPasswordLogin",
  EmojiPicker = "EmojiPicker",
  EmoteKeybindSignInBanner = "EmoteKeybindSignInBanner",
  EmotesPicker = "EmotesPicker",
  EmotesTray = "EmotesTray",
  EndGoLiveModal = "EndGoLiveModal",
  EnvironmentPicker = "EnvironmentPicker",
  EnvironmentSettings = "EnvironmentSettings",
  EnvironmentsList = "EnvironmentsList",
  ErrorScreen = "ErrorScreen",
  ExploreTab = "ExploreTab",
  FeedSpacesList = "FeedSpacesList",
  FilmingModeModal = "FilmingModeModal",
  FirstTutorial = "FirstTutorial",
  FlatMediaLightbox = "FlatMediaLightbox",
  Footer = "Footer",
  /** During auth0 migration, if user account is still auth0 after migrating database we force them to reset their password */
  ForceResetPasswordForm = "ForceResetPasswordForm",
  FreeOnlineGames = "FreeOnlineGames",
  FriendsAutoFillModal = "FriendsAutoFillModal",
  FriendsIntroModal = "FriendsIntroModal",
  FriendsListModal = "FriendsListModal",
  FriendsPage = "FriendsPage",
  FriendsSearchResults = "FriendsSearchResults",
  GoLiveModal = "GoLiveModal",
  HappeningNowCarousel = "HappeningNowCarousel",
  HeaderSection = "HeaderSection",
  HeroCarousel = "HeroCarousel",
  HeroCarouselSlide = "HeroCarouselSlide",
  HeroVideo = "HeroVideo",
  Homepage = "Homepage",
  HomepageBuildYourSpaceCarousel = "HomepageBuildYourSpaceCarousel",
  HomepageCreators = "HomepageCreators",
  HomepagePartners = "HomepagePartners",
  HomepagePress = "HomepagePress",
  HomepageTryOrDownload = "HomepageTryOrDownload",
  HostLiveEvents = "HostLiveEvents",
  HostToolsModal = "HostToolsModal",
  HyperlinksConfirmModal = "HyperlinksConfirmModal",
  IframeBlockingModal = "IframeBlockingModal",
  InsufficientFundsModal = "InsufficientFundsModal",
  LiveSpacesList = "LiveSpacesList",
  LockedModeModal = "LockedModeModal",
  LoginModal = "LoginModal",
  LoginOrSignUpEmailInput = "LoginOrSignUpEmailInput",
  LoginOrSignUpError = "LoginOrSignUpError",
  LoginPage = "LoginPage",
  LovedSpacesList = "LovedSpacesList",
  MicButton = "MicButton",
  MobilePricingPlans = "MobilePricingPlans",
  MobilePricingPlansError = "MobilePricingPlansError",
  MobilePricingPlansLoading = "MobilePricingPlansLoading",
  MobileWebAppBanner = "MobileWebAppBanner",
  MobileWebCreateSpaceInterstitial = "MobileWebCreateSpaceInterstitial",
  MobileWebSandboxInterstitial = "MobileWebSandboxInterstitial",
  MobileWebSpaceInterstitial = "MobileWebSpaceInterstitial",
  MoreGamesHomeFeed = "MoreGamesHomeFeed",
  Navbar = "Navbar",
  NewSpaceMenu = "NewSpaceMenu",
  NewestSpacesList = "NewestSpacesList",
  NotificationPermissionModal = "NotificationPermissionModal",
  OnboardingAddName = "OnboardingAddName",
  OnboardingModal = "OnboardingModal",
  OnboardingTerms = "OnboardingTerms",
  OurPartners = "OurPartners",
  ParticipantsModal = "ParticipantsModal",
  PersonalSpacesList = "PersonalSpacesList",
  PersonalTab = "PersonalTab",
  PlusCantUpgradeToSpaceSubModal = "PlusCantUpgradeToSpaceSubModal",
  PopularSpacesList = "PopularSpacesList",
  PricingPage = "PricingPage",
  ProfilePage = "ProfilePage",
  PublishedSpacesList = "PublishedSpacesList",
  /** Modal displayed when all quests are complete */
  QuestsCompleteModal = "QuestsCompleteModal",
  ReadyPlayerMeAnnouncementBanner = "ReadyPlayerMeAnnouncementBanner",
  ReadyPlayerMeCustomizationIFrame = "ReadyPlayerMeCustomizationIFrame",
  RecentSpacesList = "RecentSpacesList",
  RecommendedSpacesList = "RecommendedSpacesList",
  RecommendedSpacesOnLeave = "RecommendedSpacesOnLeave",
  RenameSpaceModal = "RenameSpaceModal",
  ReportSpaceModal = "ReportSpaceModal",
  RevampedSpaceSubscriptionCheckoutModal = "RevampedSpaceSubscriptionCheckoutModal",
  RevertSpaceModal = "RevertSpaceModal",
  RtcModal = "RtcModal",
  Safari15_4Rejection = "Safari15_4Rejection",
  SearchPage = "SearchPage",
  SelectSpaceToUpgradeModal = "SelectSpaceToUpgradeModal",
  SelectedObjectButtons = "SelectedObjectButtons",
  SharePanel = "SharePanel",
  ShareSettingsCanViewDropdown = "ShareSettingsCanViewDropdown",
  ShareSettingsDropdown = "ShareSettingsDropdown",
  ShareToSocialsModal = "ShareToSocialsModal",
  ShareYourCreation = "ShareYourCreation",
  SharedSpacesList = "SharedSpacesList",
  SignUpPasswordScreen = "SignUpPasswordScreen",
  Space = "Space",
  SpaceBanConfirmModal = "SpaceBanConfirmModal",
  SpaceEmbedPage = "SpaceEmbedPage",
  SpaceListItemHoverPreviewCard = "SpaceListItemHoverPreviewCard",
  SpaceLoadingSplash = "SpaceLoadingSplash",
  SpacePage = "SpacePage",
  SpacePageAuthFallback = "SpacePageAuthFallback",
  SpacePicker = "SpacePicker",
  SpacePickerModal = "SpacePickerModal",
  SpacePreview = "SpacePreview",
  SpacePreviewErrorLoadingContent = "SpacePreviewErrorLoadingContent",
  SpacePreviewLoadingContent = "SpacePreviewLoadingContent",
  SpaceSettingsMenu = "SpaceSettingsMenu",
  SpaceSubscriptionCheckoutFailure = "SpaceSubscriptionCheckoutFailure",
  SpaceSubscriptionCheckoutForm = "SpaceSubscriptionCheckoutForm",
  SpaceSubscriptionCheckoutFormError = "SpaceSubscriptionCheckoutFormError",
  SpaceSubscriptionCheckoutLoading = "SpaceSubscriptionCheckoutLoading",
  SpaceSubscriptionCheckoutModal = "SpaceSubscriptionCheckoutModal",
  SpaceSubscriptionCheckoutModalHasFreeUgcPlan = "SpaceSubscriptionCheckoutModalHasFreeUgcPlan",
  SpaceSubscriptionCheckoutModalHasSaasPlan = "SpaceSubscriptionCheckoutModalHasSaasPlan",
  SpaceSubscriptionCheckoutStatusModal = "SpaceSubscriptionCheckoutStatusModal",
  SpaceSubscriptionCheckoutSuccess = "SpaceSubscriptionCheckoutSuccess",
  SpaceSubscriptionUpgradeModal = "SpaceSubscriptionUpgradeModal",
  SpaceSubscriptionUpgradeModalError = "SpaceSubscriptionUpgradeModalError",
  SpaceTemplatesList = "SpaceTemplatesList",
  SpacesFeed = "SpacesFeed",
  SpacesListItemOverflowMenu = "SpacesListItemOverflowMenu",
  SpacesSearchError = "SpacesSearchError",
  SpacesSearchNoResults = "SpacesSearchNoResults",
  SpacesSearchResults = "SpacesSearchResults",
  SpatianCommunity = "SpatianCommunity",
  SplashScreen = "SplashScreen",
  SsoLogin = "SsoLogin",
  StripePaymentRedirect = "StripePaymentRedirect",
  Studio = "Studio",
  StudioBadge = "StudioBadge",
  StudioItem = "StudioItem",
  StudioNewBadge = "StudioNewBadge",
  StudioNewItem = "StudioNewItem",
  SystemPermissionRequest = "SystemPermissionRequest",
  SystemPermissionSettings = "SystemPermissionSettings",
  TeamSpacesList = "TeamSpacesList",
  TeamTab = "TeamTab",
  TemplatesList = "TemplatesList",
  ThirdPartyGamePage = "ThirdPartyGamePage",
  TokenGateAccessSettings = "TokenGateAccessSettings",
  TrendingSpacesList = "TrendingSpacesList",
  TutorialModalKeyboardInstructions = "TutorialModalKeyboardInstructions",
  TutorialModalMouseInstructions = "TutorialModalMouseInstructions",
  TutorialModalReactInstructions = "TutorialModalReactInstructions",
  TutorialModalWelcome = "TutorialModalWelcome",
  UniversalShop = "UniversalShop",
  UniversalShopAvatarStore = "UniversalShopAvatarStore",
  UniversalShopAvatarStoreCategory = "UniversalShopAvatarStoreCategory",
  UniversalShopYourItems = "UniversalShopYourItems",
  UniversalShopYourItemsCategory = "UniversalShopYourItemsCategory",
  UnlockableDanceStreakModal = "UnlockableDanceStreakModal",
  UpdatedTermsModal = "UpdatedTermsModal",
  UpsellSignup = "UpsellSignup",
  UseOnEveryDevice = "UseOnEveryDevice",
  UserBoard = "UserBoard",
  UserProfile = "UserProfile",
  UserProfileFollowing = "UserProfileFollowing",
  UserProfileFollows = "UserProfileFollows",
  UserProfilePanel = "UserProfilePanel",
  UserProfilePublishedSpaces = "UserProfilePublishedSpaces",
  VerifyEmailScreen = "VerifyEmailScreen",
  Webview = "Webview",
  WelcomeToPlusModal = "WelcomeToPlusModal",
  WhatIsSpatial = "WhatIsSpatial",
  WhatYouExperience = "WhatYouExperience",
  WhyBuildOnSpatial = "WhyBuildOnSpatial",
  WolvesDenAnnouncementModal = "WolvesDenAnnouncementModal",
}
